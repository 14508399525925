import React from 'react'
import './Footer.css'
import logo from '../images/logotipo.jpg'
import { Link } from 'gatsby'
//import gow from '../images/gow.svg'

export const Footer = () => {
    return (
        <div className="footer">
            <div className="footer__container" >
                <Link to="/" >
                    <img src={logo} alt="footer__logo" />
                </Link>

                <p>Tu relojería desde hace 50 años</p>
                <p id="gow" >web made by
                        <a
                        href="https://oscargo.es"
                        target="_blank"
                        rel="noopener noreferrer">
                        GOw
                        </a>
                </p>

            </div>
        </div>
    )
}
