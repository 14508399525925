import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useState } from "react"
import logo from '../images/cuco.png'
import './Header.css'

const Header = ({ siteTitle }) => {


    const [menu, setMenu] = useState({})
    const [burguer, setBurguer] = useState({})
    const [open, setOpen] = useState(false)

    const handleMenu = () => {
        if (open) {
            setBurguer({ transform: 'rotate(90deg)' })
            setMenu({
                left: '100vw'
            })
            setOpen(false)
        } else {
            setBurguer({ transform: 'rotate(-90deg)' })
            setMenu({
                left: '0',
            })
            setOpen(true)
        }

    }
    const hideMenu = () => {
        setBurguer({ transform: 'rotate(90deg)' })
        setMenu({
            left: '100vw'
        })
        setOpen(false)
    }
    return (

        <header id="header"  >
            <div role="menu" onClick={hideMenu} className="cabecera" >
                <Link to="/">
                    <div className="logo" >
                        <img src={logo} alt="logo" /> <h2>RJGómez</h2>
                    </div>
                </Link>

                <nav role="menubar" onClick={hideMenu} className="navegacion" style={menu}>
                    <Link to="/como-trabajamos" className="navegacion__link" >¿Cómo trabajamos?</Link>
                    <Link to="/galeria" className="navegacion__link" >Galeria</Link>
                    <Link to="/nuestras-marcas" className="navegacion__link" >Marcas</Link>
                </nav>

            </div>
            <span role="button" id="burguer" style={burguer} onClick={handleMenu} className="navegacion__link " >|||</span>
        </header>

    )
}
Header.propTypes = {
    siteTitle: PropTypes.string,
}

Header.defaultProps = {
    siteTitle: ``,
}

export default Header
