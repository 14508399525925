import React from 'react'
import './Contacto.css'

export const Contacto = () => {
    return (
        <footer>
            <hr style={{ width: '80%', margin: '0 auto' }} />
            <div className="contacto">
                <div className="contacto__container" >
                    <div className="contacto__info" >
                        <h3> Datos de contacto: </h3>
                        <p> Relojería Joyería Gómez </p>
                        <p>C/Alexandre Bóveda nº20</p>
                        <a href="tel:+34986501299">Tlf:  986501299</a>
                    </div>
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1468.5731852898618!2d-8.768190291092735!3d42.5946379126897!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd2f1417bdd35789%3A0xa3cee4ca510cc2da!2zUmVsb2plcsOtYSBKb3llcsOtYSBHw7NtZXo!5e0!3m2!1ses!2ses!4v1605374511625!5m2!1ses!2ses"
                        width="280" height="130" frameBorder="0"
                        style={{ border: 0, margin: '20px 0', padding: '0' }}
                        allowFullScreen=""
                        aria-hidden="false"
                        tabIndex="0"
                        title="relojeria gómez">

                    </iframe>
                    <div className="contacto__horario" >
                        <h3>Horario</h3>
                        <p><span>Lunes-Viernes:<br /></span> 9:45–13:45, 16:30–20:30</p>
                        <p><span>Sábados:<br /></span> 9:45–13:45</p>
                    </div>

                </div>
            </div>
        </footer>
    )
}